

import { defineComponent } from 'vue';
import Base from '@/mixins/Base.vue';

export default defineComponent({
    mixins: [Base],
    watch: {
        active(value) {
            if (value) {
                this.getSurveys();
                this.getProductSurveys();
            }
        },
    },
    props: {
        active: {
            type: Boolean,
            default: false,
        },
        item: {
            type: Object,
            default: undefined,
        },
    },
    mounted() {

    },
    data() {
        return {
            surveys: {
                data: [] as Array<any>,
                meta: {
                    total: 0,
                },
            },
            isAddProductSurveysModal: false,
            newProductSurvey: {
                when: '',
                by: '',
                survey_id: '',
            },
        };
    },
    methods: {
        getSurveys() {
            this.$http
                .post(
                    process.env.VUE_APP_SURVEY_ENDPOINT,
                    {
                        grant_type: 'client_credentials',
                        client_id: process.env.VUE_APP_SURVEY_CLIENT_ID,
                        client_secret: process.env.VUE_APP_SURVEY_CLIENT_SECRET,
                    },
                    {
                        headers: {
                            'Content-type': 'application/x-www-form-urlencoded',
                        },
                    },
                )
                .then((response) => {
                    const accessToken = response.data.access_token;
                    this.$http
                        .get(process.env.VUE_APP_SURVEY_ENDPOINT, {
                            params: {},
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            },
                        })
                        .then(({ data }) => {
                            this.surveys.data = data.data;
                            this.surveys.meta = data.meta;
                        });
                });
        },
        getProductSurveys() {
            this.get(`products/${this.$route.params.id}/surveys`)
                .then(({ data }) => {
                    this.item.surveys = data.data;
                });
        },
        addProductSurvey() {
            this.post(`products/${this.$route.params.id}/surveys`, {
                ...this.newProductSurvey,
            })
                .then(() => {
                    this.getProductSurveys();
                    this.isAddProductSurveysModal = false;
                });
        },
        saveProductSurvey(data: any) {
            this.put(`products/${this.$route.params.id}/surveys/${data.id}`, {
                ...data,
            })
                .then(() => {
                    this.getProductSurveys();
                    this.isAddProductSurveysModal = false;
                });
        },
        deleteProductSurvey(id: string) {
            this.delete(`products/${this.$route.params.id}/surveys/${id}`)
                .then(() => {
                    this.getProductSurveys();
                });
        },
    },

});
