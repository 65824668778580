

import { defineComponent } from 'vue';
import Base from '@/mixins/Base.vue';
import { IBuildingType } from '@/interfaces/buildingtype';
import { IRegion } from '@/interfaces/region';

export default defineComponent({
    mixins: [Base],
    watch: {
        active(value) {
            if (value) {
                this.getBuildingTypes();
                this.getRegions();
                this.getProductVariants();
            }
        },
    },
    props: {
        active: {
            type: Boolean,
            default: false,
        },
        item: {
            type: Object,
            default: undefined,
        },
    },
    mounted() {

    },
    data() {
        return {
            buildingTypes: [] as Array<IBuildingType>,
            regions: [] as Array<IRegion>,
            isAddProductVariantsModal: false,
            newProductVariant: {
                name: '',
                code: '',
                building_type: undefined as any | IBuildingType,
                building_type_id: null,
                region: undefined as any | IRegion,
                region_id: null,
            },
        };
    },
    methods: {
        getProductVariantByRegionAndBuildingType(region_id: string, building_type_id: string) {
            return this.item.variants.find((p: any) => p.region_id === region_id && p.building_type_id === building_type_id);
        },
        getBuildingTypes(): void {
            this.get('building-types')
                .then(({ data }: any) => {
                    this.buildingTypes = [
                        {
                            id: null,
                            name: 'None',
                            spoq_id: 0,
                        },
                        ...data.data,
                    ];
                });
        },
        getRegions(): void {
            this.get('regions')
                .then(({ data }: any) => {
                    this.regions = [
                        {
                            id: null,
                            name: 'None',
                            spoq_id: 0,

                        },
                        ...data.data,
                    ];
                });
        },
        addProductVariant() {
            this.post(`products/${this.$route.params.id}/variants`, {
                ...this.newProductVariant,
                region_id: this.newProductVariant.region?.id,
                building_type_id: this.newProductVariant.building_type?.id,
            })
                .then(() => {
                    this.getProductVariants();
                    this.isAddProductVariantsModal = false;
                });
        },
        getProductVariants() {
            this.get(`products/${this.$route.params.id}/variants`)
                .then(({ data }) => {
                    this.item.variants = data.data;
                });
        },
        deleteProductVariant(id: string) {
            this.delete(`products/${this.$route.params.id}/variants/${id}`)
                .then(() => {
                    this.getProductVariants();
                });
        },
    },

});
