

import { defineComponent } from 'vue';
import Base from '@/mixins/Base.vue';

export default defineComponent({
    mixins: [Base],
    watch: {
        active(value) {
            if (value) {
                this.getSubproducts();
            }
        },
    },
    props: {
        active: {
            type: Boolean,
            default: false,
        },
        item: {
            type: Object,
            default: undefined,
        },
    },
    mounted() {
    },
    data() {
        return {
            isAddSubProductsModal: false,
            newSubproduct: {
                name: '',
                code: '',
            },
        };
    },
    methods: {
        addSubproduct() {
            this.post(`products/${this.$route.params.id}/subproducts`, {
                ...this.newSubproduct,
            })
                .then(() => {
                    this.getSubproducts();
                    this.isAddSubProductsModal = false;
                });
        },
        deleteSubproduct(id: string) {
            this.delete(`products/${this.$route.params.id}/subproducts/${id}`)
                .then(() => {
                    this.getSubproducts();
                });
        },
        getSubproducts() {
            this.get(`products/${this.$route.params.id}/subproducts`)
                .then(({ data }) => {
                    this.item.subproducts = data.data;
                });
        },
    },

});
